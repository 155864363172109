@media (max-width: 475px) {
    .popover{
        min-width: 355px!important;
        width: 355px!important;

        top: 40px !important;
        left: 13px !important;

        box-shadow: 0 0 20px 0 rgba(0,0,0,0.13);
    }

    .popover .arrow {
        display: none;
    }
    .popover-body .input-group {
        flex-direction: column;
    }
    .popover-body .input-group .date-picker,
    .popover-body .input-group .date-picker .react-datepicker-wrapper,
    .popover-body .input-group .date-picker .react-datepicker-wrapper input {
        width: 100% !important;
    }
    .date-label {
        margin-left: 1px !important;
        margin-bottom: 5px !important;
        font-size: 16px;
    }
    .modal-body .react-datepicker-wrapper {
        width: 88%;
    }

}

.date-picker {
    display: flex;
    align-self: baseline;
}