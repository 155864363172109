body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Definition of app font helper classes */

.font-12, .font-14, .font-16, .font-22, .font-28, .font-30, .font-36, .font-48 {
  /* font-family: 'Roboto'; */
  letter-spacing: 0;
}

.font-12 {
  font-size: 12px;
  line-height: 14px;
}

.font-14 {
  font-size: 14px;
  line-height: 16px;
}

.font-16 {
  font-size: 16px;
  line-height: 19px;
}

.font-22 {
  font-size: 22px;
  line-height: 25px;
}

.font-28 {
  font-size: 28px;
  line-height: 33px;
}

.font-30 {
  font-size: 30px;
  line-height: 35px;
}

.font-36 {
  font-size: 36px;
  line-height: 42px;
}

.font-48 {
  font-size: 48px;
  line-height: 56px;
}

.font-black {
  color: #32303B;
}

.font-gray {
  color: #6C6977;
}

.font-lightgray {
  color: #9C92A0;
}

.font-green {
  color: #458600;
}

.font-red {
  color: #E02020;
}

.font-white {
  color: #FFFFFF!important;
}

.menu-text {
  color: #FFFFFF!important;
}

.menu-text:hover {
  color: #f7f7f7!important;
}

.app-clickable {
  cursor: pointer!important;
}

.menu-text-notactive {
  opacity: 0.6;
}

.custom-navbar {
  z-index: 1040!important;
}

.with-caret::after {
  display: inline-block;
  margin-left: 0.4em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.action-icon-disabled, .action-icon-disabled:hover, .action-icon-disabled:focus, .action-icon-disabled:active {
  color: #d7d3d9!important;
}

.action-icon-disabled:hover, .action-icon-disabled:focus, .action-icon-disabled:active {
  cursor: not-allowed!important;
}

.action-icon {
  color: #9C92A0;
}

.action-icon:hover, .action-icon:focus, .action-icon:active {
  cursor: pointer;
  color: #7e7182;
}

.app-link {
  color: #984DFF!important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.app-link:hover, .app-link:focus, .app-link:active {
  color: #842bff!important;
  cursor: pointer!important;
}

.app-chart-link {
  color: #6a667d!important;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.app-chart-link-active {
  font-weight: bold;
}

.app-chart-link:hover, .app-chart-link:focus, .app-chart-link:active {
  color: #32303B!important;
  cursor: pointer!important;
}

.modal-350 {
  width: 350px!important;
  margin: auto;
  margin-top: 300px;
}

.app-input::placeholder {
  color: #6C6977;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 16px;
}

.btn-primary.link-button {
  height: 36px;
  width: 135px;
  border-radius: 4px;
  background-color: #FFFFFF !important;
  border-color: #FFAEBF !important;
}

.btn-primary.link-button>.link-button-text {
  background: linear-gradient(138.37deg, #FFAEBF 0%, #B783FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFAEBF !important;
}

.btn-primary.link-button:hover {
  background-color: #f7f7f7!important;
  border-color: #FFAEBF !important;
}

.btn-primary.link-button:focus {
  background-color: #efefef !important;
  border-color: #FFAEBF !important;
}

.btn-primary.link-button:disabled, .btn-primary.main-button:disabled, .btn-primary.twitter-button:disabled, .btn-primary.google-button:disabled {
  cursor: not-allowed;
}

.main-button-sm {
  width: 200px!important;
  height: 40px!important;
}

@media (max-width: 767px) {
  .main-button-responsive {
    width: 100px!important;
  }
}

.btn-primary.main-button, .btn-primary.twitter-button, .btn-primary.google-button {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.btn-primary.main-button {
  background: linear-gradient(138.37deg, #FFAEBF 0%, #B783FF 100%) !important;
}

.btn-primary.main-button:hover {
  background: linear-gradient(138.37deg, #ff839d 0%, #A05CFF 100%) !important;
}

.btn-primary.main-button:focus, .btn-primary.main-button:active {
  background: linear-gradient(138.37deg, #fe587b 0%, #8a35ff 100%) !important;
}

.btn-primary.twitter-button {
  background-color: #69ACE0 !important;
}

.btn-primary.twitter-button:hover {
  background-color: #4D9CDA !important;
}

.btn-primary.twitter-button:focus, .btn-primary.twitter-button:active {
  background-color: #328dd4 !important;
}

.btn-primary.google-button {
  background-color: #5085ED !important;
}

.btn-primary.google-button:hover {
  background-color: #3370EA !important;
}

.btn-primary.google-button:focus, .btn-primary.google-button:active {
  background-color: #175de5 !important;
}

.app-navbar-55 {
  height: 55px;
}

.app-navbar-110 {
  height: 110px;
}

.app-navbar-210 {
  height: 210px;
}

.app-navbar-body-55 {
  margin-top: 55px;
}

.app-navbar-body-110 {
  margin-top: 110px;
}

.app-navbar-body-210 {
  margin-top: 210px;
}

@media (min-width: 768px) {
  .app-navbar-110 {
    height: 55px!important;
  }
  .app-navbar-body-110 {
    margin-top: 55px!important;
  }
}

.app-navbar {
  background: linear-gradient(228.37deg, #FFAEBF 0%, #B783FF 100%);
}

.app-navbar .dropdown-item:hover {
  background-color: #f7f7f7!important;
  color: #212529!important;
}

.app-navbar .dropdown-item:active {
  background-color: #efefef!important;
  color: #212529!important;
}

.app-navbar .navbar-brand:hover {
  cursor: pointer;
}

.navbar-logo {
  height: 40px;
}

.user-photo {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -o-border-radius: 15px;
}

div.tobedone:before {
  content: "NOT YET IMPLEMENTED";
  font-weight: 1000;
  color: grey;
}

div.tobedone {
  border: 3px dotted;
  background-color: lightgray;
  min-height: 435px!important;
}

.app-responsive-form {
  width: 500px;
  margin: 0 auto;
}

.users-container-fix {
  margin-top: 0px!important;
}

@media (max-width: 767px) {
  .custom-navbar {
    margin-top: -3px;
  }
  .card.app-responsive-container {
    overflow-x: scroll;
  }
  .card.app-responsive-container .card-body {
    overflow-x: auto;
    min-width: 700px;
  }
  .app-responsive-header {
    font-size: 30px;
    line-height: 35px;
  }
  .app-responsive-form {
    width: 100%;
  }
}

/* Chart related stylings */

div.app-chart-container {
  min-height: 435px!important;
}

div.app-chart {
  min-height: 335px!important;
  max-height: 335px!important;
}

div.app-chart-short {
  min-height: 324px!important;
  max-height: 324px!important;
}

.ReactChart.app-custom-chart div.tooltip-wrap {
  z-index: 100;
}

.ReactChart.app-custom-chart div.tooltip-wrap>div:first-child>div:first-child>div:first-child {
  background: linear-gradient(138.37deg, #FFAEBF 0%, #B783FF 100%)!important;
}

.ReactChart.app-custom-chart div.tooltip-wrap>div:first-child>div:first-child>div:first-child>div:first-child {
  border-top: 7px solid #B783FF!important;
}

table.custom-chart-tooltip {
  white-space: nowrap;
}

table.custom-chart-tooltip>tbody>tr {
  font-weight: bold;
}

table.custom-chart-tooltip>tbody>tr>td:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.statitics-card {
  height: 80px;
}

.statitics-card-clickable {
  height: 80px;
  background-color: #FFFFFF;
}

.statitics-card-clickable:hover, .statitics-card-clickable:focus, .statitics-card-clickable:active {
  background-color: #f7f7f7!important;
  cursor: pointer!important;
}

.popover {
  min-width: 400px!important;
  width: 400px!important;
}

.datepicker-range {
  width: 125px!important;
}

/* Override @khanacademy/react-multi-select styling */

.multi-select .dropdown-heading {
  border-color: rgb(206, 212, 218)!important;
}

.multi-select .dropdown:focus, .multi-select .dropdown:active, .multi-select .dropdown:focus-within {
  border-color: #80bdff!important;
  border-bottom-left-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  border-top-left-radius: 4px!important;
  border-top-right-radius: 4px!important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)!important;
}

.multi-select .select-panel>div:first-child>input {
  border-color: rgb(206, 212, 218)!important;
}

.multi-select .select-panel>div:first-child {
  padding: 0.25em!important;
}

.multi-select .select-item {
  margin-top: 0px;
  margin-bottom: 0px;
}

.multi-select .select-item>span>span {
  margin-top: -7px!important;
}

.footer-part {
  width: 100%;
}

@media (min-width: 992px) {
  .footer-part {
    width: 50%!important;
  }
}

/* react-toastify overrides */

.Toastify__toast {
  min-height: 50px;
}

.Toastify__toast--success {
  background: #B783FF;
}

@media (max-width: 475px) {
  .main-button-sm {
    width: 140px !important;
  }
  .modal-350 {
    margin-top: 200px;
  }
}
