.main-dropdown {
    position: absolute;
    top: 100%;
}
@media (max-width: 767px) {
    img.navbar-logo {
        position: absolute;
        top: 12px;
    }
    nav .form-inline{
        justify-content: flex-end;
    }
    nav .form-inline .user-photo {
        margin-right: 0 !important;
    }
}