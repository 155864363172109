.chartWrapper {
    margin: 0 !important;
}
@media (max-width: 475px) {
    .card.app-responsive-container .card-body {
        min-width: 330px !important;
        padding: 10px;
    }
    .app-chart-container .row:first-child {
        flex-direction: column;
    }
    .app-chart-container .row:first-child b{
        font-size: 20px !important;
        margin-bottom: 20px;
    }

    .app-chart-container .row:first-child>div{
        margin: 0 !important;
        float: none !important;

        display: flex;
        justify-content: space-between;
    }
    .app-chart-container .row:first-child>div a:first-child {
        margin-left: 0 !important;
    }
    .app-chart-container .row:first-child>div a {
        font-size: 12px;
    }
}