.auth-actions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 0 6px;
}

@media (max-width:1230px) and (min-width:1200px) {
    .auth-actions .app-link{
        font-size: 15px;
    }
}

@media (max-width: 1200px) {
    .auth-row {
        flex-direction: column;
    }
    .auth-actions label,
    .auth-actions .app-link {
        font-size: 14px;
    }
}

@media (max-width: 475px) {
    .auth-row h1 {
        font-size: 22px;
        text-align: center;
    }
    .auth-content {
        padding-left: 10px;
        padding-right: 10px;
    }
    .auth-content>.col-md-6 {
        padding: 0;
        text-align: center !important;
    }
    .auth-content>.col-md-6>form{
        text-align: left !important;
    }
    .auth-actions label,
    .auth-actions .app-link {
        font-size: 16px;
    }
    hr{
        margin-top: .2rem;
        margin-bottom: .2rem;
    }
    .bottom-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bottom-text a{
        margin-top: 10px;
        margin-bottom: 30px;
    }
}

