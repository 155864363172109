.intro-panel {
    -ms-flex: 0 0 500px;
    flex: 0 0 500px;
    width: 100%;
    background: linear-gradient(138.37deg, #FFAEBF 0%, #B783FF 100%);
    min-height: 830px;
}

.logo-img {
    width: 400px;
    padding-left: 50px;
    padding-top: 30px;
}

.logo-text {
    width: 450px;
    margin-left: 25px;
    margin-top: 30px;
}

.logo-icon {
    width: 120px;
    margin-left: 190px;
    margin-top: 30px;
}

.intro-panel-tagline {
    color: #FFFFFF;
    font-family: Ubuntu;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
}


@media (max-width: 1200px) {
    .intro-md-hide {
        display: none;
    }
    .intro-panel {
        -ms-flex: 0 0 100px;
        flex: 0 0 100px;
        min-height: 100px;
    }
    .intro-panel {
        text-align: center;
    }
}

@media (max-width: 475px) {
    .logo-text {
        width: 320px;
        margin-left: 0;
    }
}
